import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  refundReservation: Yup.bool(),

  rejectionReason: Yup.string()
    .required('Cancellation Reason is required'),

  rejectionText: Yup.string(),

  reservations: Yup.array()
    .min(1, 'Please select at least 1 reservation.'),
})
