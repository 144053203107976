import cnames from 'classnames'
import { formatDate } from 'Utils/Dates'
import { Col } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import './ReservationCard.scss'

const ReservationCard = ({ code, from, id, installmentFirst, isParent, links, redirectUrl, selected, site, state,
                           to, uuid }) => {
  const isCancelled = state === 'cancelled'
  const isSelected = [id, uuid].includes(selected)
  const colClasses = cnames('reservation-card card border bg-light rounded text-gray mx-auto shadow-sm',
                            'mx-md-0 mb-0 me-md-3 p-2 mt-4', { 'border border-primary selected': isSelected,
                                                                cancelled: isCancelled })
  const linkIconClasses = cnames('ms-2 text-primary', { 'text-secondary': isCancelled })
  const viewingBadgeClasses = cnames('viewing-badge bg-action position-absolute rounded-top text-smaller-2',
                                      'text-uppercase', { cancelled: isCancelled })

  return (
    <Col className={colClasses}>
      <a className="text-decoration-none" href={links[redirectUrl]} rel="noreferrer" target="_blank">
        {isSelected && (
          <span className={viewingBadgeClasses}>
            Viewing
          </span>
        )}

        <div className="site-dates-text text-grey-800">
          {site.name} -

          <i className="ms-1">
            {`${formatDate(from, { format: 'MM/DD' })} - ${formatDate(to, { format: 'MM/DD' })}`}
          </i>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <span className="code-text text-gray-700 text-smaller-2">
            {code}

            {isCancelled && <span className="text-rose-gold text-italic"> - Cancelled</span>}
          </span>

          <div>
            {isParent && (
              <span className="text-grey-800">{installmentFirst?.total?.display}</span>
            )}

            {!isSelected && <FontAwesomeIcon className={linkIconClasses} icon="arrow-up-right-from-square" />}
          </div>
        </div>
      </a>
    </Col>
  )
}

export default ReservationCard
