import { gql } from 'Utils/apolloClient'

const campsitesQuery = gql`
  query Campsites($checkIn: ISO8601Date!, $checkOut: ISO8601Date!, $exceptions: [AvailabilityExceptionsAttributes!]
                  $filters: ValidationsFiltersAttributes, $includePastDates: Boolean,
                  $overrideRules: OverrideRulesAttributes, $rateId: ID, $siteId: ID, $slug: String!) {
    campground(filters: $filters, from: $checkIn, isPos: true, slug: $slug, to: $checkOut) {
      id
      ratesAvailableDuring (exceptions: $exceptions, includePastDates: $includePastDates,
                            overrideRules: $overrideRules, rateId: $rateId, siteId: $siteId) {
        hasDailyPrices
        id
        maxLength
        maxWidth
        minNightsRequired
        name
        nightPrice {
          amount
          display
          displayWithCode
        }
        rigRequired
        sites {
          amenitiesSummary
          eventNextBooking {
            datesLabel
            from
            id
            isSameDay
          }
          id
          maxLength
          maxWidth
          maxPeople
          name
          reservableByGuest
        }
        siteTypeName
        taxConfig {
          rateValue
        }
        totalNights
        totalPrice {
          amount
          display
        }
      }
    }
  }`

export default campsitesQuery
