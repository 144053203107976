import { useState } from 'react'
import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import { Button } from 'reactstrap'
import CancelModal from './CancelModal'

const App = props => {
  const [isOpen, setIsOpen] = useState(false)
  const { engineName } = props
  const isAccountEngine = engineName === 'accounts'
  window.roverData = { ...window?.roverData, ...props, isAccountEngine }

  const toggle = () => setIsOpen(prevState => !prevState)

  return (
    <ApolloProvider client={apolloClient}>
      <Button className="me-0" color="red" onClick={toggle}>Cancel Reservation</Button>

      <CancelModal isOpen={isOpen} toggle={toggle} />
    </ApolloProvider>
  )
}

export default App
