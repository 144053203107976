import moment from 'moment-timezone'

const timeContainer = document.getElementsByClassName('campground-timezone')[0]
const { timezone } = timeContainer?.dataset || window.roverData || {}
export const longFormatDate = 'MMMM DD, YYYY h:mm A zz'
export const dateWithSlashes = 'MM/DD/YYYY'
export const dateWithTimezone = 'MM/DD/YY HH:mm zz'
export const hourWithTimezone = 'HH:mm zz'
export const flexibleCheckoutDate = '2099-12-31'

if (timezone) moment.tz.setDefault(timezone)

export const YEARLESS_FROM = moment('2060-12-31')

export const YEARLESS_TO = moment('2060-01-01')

export { moment }

export const abbrTimezone = () => moment().tz(timezone).zoneAbbr()

export const addDateTime = (date, timeAdd) => parseMoment(date).add(timeAdd).clone()

export const today = () => moment().startOf('date')

export const monthNumberToName = monthNumber => moment().month(monthNumber).format('MMMM')

export const numberOfNights = (from, to) => {
  if (!isValidDatesRange({ from, to })) return false

  return parseMoment(to).diff(parseMoment(from), 'days')
}

export const dateBetween = (date, props = { from: '', to: '' }, inclusion = '[]') => {
  let { from, to } = props
  if (!date || !from || !to) return false

  date = parseMoment(date)
  from = parseMoment(from)
  to = parseMoment(to)
  return date.isBetween(from, to, 'day', inclusion)
}

export const dateToISO = date => moment(date).format('YYYY-MM-DDTHH:mm:ssZ')

export const daysDifferenceFromToday = date => {
  const start = parseMoment(date)
  const end = today()

  return moment.duration(start.diff(end)).asDays()
}

export const formatDate = (date, props = { format: 'YYYY-MM-DD', inputFormat: '' }) => {
  if (!date) return null

  const { format, inputFormat } = props
  date = moment(date, inputFormat)
  return date.format(format)
}

export const formatDateRange = (from, to, props = { format: 'YYYY-MM-DD' }) => {
  if (!!from && !!to) {
    const displayTo = to === 'TBD' ? to : formatDate(to, props)

    return `${formatDate(from, props) } - ${displayTo}`
  }

  return null
}

export const parseDay = day => {
  if (!day || day < 1 || day > 31) return null

  return moment().month(0).date(day).format('Do')
}

export const formatToday = (format = 'YYYY-MM-DD') => moment().format(format)

export const parseDate = str => {
  const date = parseMoment(str)
  return date && date._d
}

export const parseMoment = (str, props = { format: '', formatReturned: 'YYYY-MM-DD', isFormatNeeded: false }) => {
  if (!str) return null

  const { format, formatReturned, isFormatNeeded } = props
  const date = moment(str, format)
  if (!date.isValid()) return null

  if (isFormatNeeded) return date.format(formatReturned)

  return date
}

export const isAfterConsideringYearWrap = (momentA, momentB) => {
  if (momentA.isAfter(momentB))
      return true

  if (momentA.month() === 11 && momentB.month() === 0)
    if (momentB.year() === momentA.year() + 1)
        return true

  return false
}

export const flexibleCheckoutMoment = parseMoment(flexibleCheckoutDate)

export const isFutureDate = ({ from, maxYears = 3 }) => {
  const momentFrom = moment(from)
  const isStartSameOrAfterToday = momentFrom.isSameOrAfter(today())
  if (!isStartSameOrAfterToday) return false

  const maxLimitDate = today().add(maxYears, 'years')
  const isStartBefore = momentFrom.isBefore(maxLimitDate)
  return isStartBefore
}

export const isToday = date => today().isSame(date, 'day')

export const isTodayOrBefore = date => moment(date).isSameOrBefore(today())

export const isValidDatesRange = ({ from, to }) => {
  if (!(from && to)) return false

  const momentFrom = moment(from)
  const momentTo = moment(to)
  const areMomentValidDates = momentFrom.isValid() && momentTo.isValid()

  if (!areMomentValidDates) return false

  const isStartBeforeEnd = momentFrom.isBefore(momentTo)

  return isStartBeforeEnd
}

export const isPastDate = (current, next, format = 'YYYY-MM-DD') => {
  if (!current || !next) return true

  const currentDate = moment(current, format)
  const nextDate = moment(next, format)

  return moment(currentDate).isBefore(nextDate)
}

export const validStringDate = str => {
  if (!str) return false

  const date = moment(str)
  return date._isValid
}
