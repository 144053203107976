import cnames from 'classnames'
import { useFormikContext } from 'formik'
import { formatDate } from 'Utils/Dates'
import CheckboxTable from 'Shared/Admin/Table/CheckboxTable'
import FormSwitch from 'Shared/Formik/FormSwitch'
import InfoAlert from 'Shared/GroupBookingsBar/InfoAlert'
import 'Shared/GroupBookingsBar/GroupBookingsBar.scss'

const CHILD_MESSAGE = 'To refund additional payments on this reservation, please visit the reservations Refunds tab after cancelling.'

const ReservationRow = ({ item, onCheckRow }) => {
  const { isAccountEngine } = window.roverData
  const { canRefundPayment, from, isParent, isRequest, name, pricing: { maxRefundableAmount },
          rate, site, to } = item
  const { setFieldValue, values: { refundReservation, reservations } } = useFormikContext()
  const amountClass = cnames('reservation-amount', { 'text-gray-600': !isParent })
  const nameCheckBox = 'select-current-check'
  const selected = reservations.includes(item.id)

  const handleCheckRow = () => onCheckRow(item.id)

  const onRefundSelect = value => setFieldValue('refundReservation', value)

  return (
    <div className="reservation-row mb-3">
      <div className="border border-1 rounded shadow-sm">
        <div aria-hidden="true" className="row table-list--row m-0 p-2 align-items-center"
             onClick={handleCheckRow}>
          <div className="row-cell row-cell--checkbox col-1 text-center">
            <CheckboxTable handleClick={handleCheckRow} id={`${nameCheckBox}-${item.id}`} isChecked={selected}
                           message="Select Row" name={nameCheckBox} />
          </div>

          <div className="row-cell text-break row-cell--amount col-8 col-lg-9">
            <p className="mb-2">
              {isRequest ? rate.name : site.name} -
              <i className="ms-1">
                {`${formatDate(from, { format: 'MM/DD' })} - ${formatDate(to, { format: 'MM/DD' })}`}
              </i>
            </p>

            <small className="text-uppercase text-gray-700">{name}</small>
          </div>

        {!isAccountEngine && (
          <div className="row-cell text-break row-cell--amount col-3 col-lg-2 text-end">
            {canRefundPayment &&
              <span className={amountClass}>{maxRefundableAmount.display}</span>
            }
          </div>
        )}
        </div>

        {(!isAccountEngine && selected && isParent && canRefundPayment) && (
          <FormSwitch checked={refundReservation} classLabel="fw-normal" className="bg-light m-3 mt-0 p-3 rounded"
                      name="refundReservation" label="Do you want to refund this reservation?"
                      onChange={onRefundSelect} onColor="#007BFF" />
        )}
      </div>

      {(!isAccountEngine && selected && canRefundPayment && !isParent) && (
        <InfoAlert message={CHILD_MESSAGE} />
      )}
    </div>
  )
}

export default ReservationRow
