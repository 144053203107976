import { useEffect, useState } from 'react'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient/useLazyQueryNoCache'
import reservationsQuery from './reservationsQuery'
import { ModalBody } from 'reactstrap'
import AlertErrors from 'Shared/Alert/AlertErrors'
import CancelForm from './CancelForm'
import Loading from 'Shared/Loading'
import Modal from 'Shared/ModalCustomClose'
import 'Shared/Skeleton/Skeleton.scss'

const CancelModal = ({ isOpen, toggle }) => {
  const { engineName, slug, uuid } = window.roverData
  const [getReservation, { data, error, loading }] = useLazyQueryNoCache(reservationsQuery)
  const [reservation, setReservation] = useState()
  const [cancellationPolicies, setCancellationPolicies] = useState({})

  useEffect(() => {
    if (isOpen) getReservation({ variables: { engineName, slug, uuid } })
    else if (data) setReservation(null)
  }, [isOpen])

  useEffect(() => {
    if (data?.campground?.reservation)
      setReservation(data?.campground?.reservation)
      setCancellationPolicies({
        campgroundCancellationPolicy: data?.campground?.campgroundCancellationPolicy,
        campgroundCancellationPolicyNote: data?.campground?.campgroundCancellationPolicyNote,
        roverpassCancellationPolicy: data?.campground?.roverpassCancellationPolicy,
      })
  }, [data])

  return (
    <Modal className="cancel-modal salmon-header-modal red-header" isOpen={isOpen} size="lg"
           title="Cancel Reservations" toggle={toggle}>
      <ModalBody className="text-grey-800">
        {(error && !data) && (
          <AlertErrors className="m-3" graphQLErrors={error} />
        )}

        {loading && (
          <Loading className="text-center mb-2" iconClass="mt-5" size="2x" />
        )}

        {reservation?.reservationsGroupNotCancelled && (
          <>
            <strong>Reservation is part of a Group Booking.</strong>

            <div>Which reservations do you want to cancel?</div>

            <CancelForm {...reservation} toggle={toggle} cancellationPolicies={cancellationPolicies} />
          </>
        )}
      </ModalBody>
    </Modal>
  )
}

export default CancelModal
