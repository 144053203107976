import { createSlice } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'
import { asyncQueryBuilder } from 'Admin/Store/asyncQueryBuilder'
import { skeletonPrice } from 'Checkout/Utils/skeletonPrice'
import { asyncThunkMutationWithErrors, asyncThunkQueryWithErrors } from 'Admin/Store/asyncThunkHandler'
import pricingQuery from 'Checkout/Queries/PricingQuery'
import reservationValidateMutation from 'Checkout/Mutation/ReservationValidateMutation'
import CartState from 'Checkout/Redux/InitialStates/CartState'

export const getPricing = asyncThunkQueryWithErrors(
  'checkout/getPricing',
  pricingQuery,
  ({ data }) => data.campground
)

export const validateCartReservations = asyncThunkMutationWithErrors(
  'checkout/validateCartReservations',
  reservationValidateMutation,
  ({ data }) => data.reservationValidate
)

const CartSlice = createSlice({
  name: 'cart',
  initialState: CartState,
  reducers: {
    addReservation: (state, { payload }) => {
      const newReservations = [...state.reservations, { nanoId: nanoid(), ...payload }]
      state.reservations = newReservations
    },
    clearCart: state => {
      state.editingReservationId = ''
      state.pricing = CartState.pricing
      state.reservations = []
    },
    removeReservation: (state, { payload }) => {
      const newReservations = state.reservations.filter(reservation => reservation.nanoId !== payload.nanoId)
      state.reservations = newReservations
    },
    restoreReservations: (state, { payload }) => {
      state.reservations = payload
    },
    setEditingReservationId: (state, { payload }) => {
      state.editingReservationId = payload
    },
    setPaymentProcessing: (state, { payload }) => {
      // TODO: Move to payment slice when we create it
      state.paymentProcessing = payload
    },
    updateReservation: (state, { payload }) => {
      const newReservations = state.reservations.map(reservation => {
        if (reservation.nanoId !== state.editingReservationId) return reservation

        return { ...reservation, ...payload.reservation }
      })

      state.reservations = newReservations
      if (!payload.keepStore) state.editingReservationId = ''
    },
  },
  extraReducers: builder => {
    asyncQueryBuilder(builder, getPricing, {
      fulfilled: (state, { payload }) => {
        const { __typename: _, ...result } = payload.purchasePricing

        state.pricing = {
          data: result,
          error: null,
          loading: false,
        }
      },
      pending: state => {
        const data = state.pricing.data.reservations.length ? state.pricing.data : skeletonPrice

        state.pricing = {
          data,
          error: null,
          loading: true,
        }
      },
      rejected: (state, { payload }) => {
        state.pricing.error = payload
        state.pricing.data = CartState.pricing.data
        state.pricing.loading = false
      },
    })
    asyncQueryBuilder(builder, validateCartReservations, {
      fulfilled: (state, { payload: { __typename: _, ...payload } }) => {
        state.reservationsValidate.error = null
        state.reservationsValidate.loading = false
        state.reservationsValidate.data = payload
      },
      pending: state => {
        state.reservationsValidate = CartState.reservationsValidate
      },
      rejected: (state, { payload }) => {
        state.reservationsValidate.error = payload
        state.reservationsValidate.loading = false
        state.reservationsValidate.data = CartState.reservationsValidate.data
      },
    })
  },
})

export const { addReservation, clearCart, removeReservation, restoreReservations, setEditingReservationId,
               setPaymentProcessing, updateReservation } = CartSlice.actions

export default CartSlice.reducer
