import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Label, Input, InputGroup, InputGroupText } from 'reactstrap'
import FormGroupField from 'Shared/Formik/FormGroupField'

const Coupon = ({ couponCode, disabled }) => {
  const [localValue, setLocalValue] = useState(couponCode)
  const [clearCouponCode, setClearCouponCode] = useState(false)
  const couponCodeSaved = useSelector(state => state.payment.couponCode)

  return (
    <FormGroupField className="mb-0" name="couponCode">
      {({ form }) => {
        const onClickApply = () => {
          form.setFieldValue('couponCode', localValue)
          setClearCouponCode(true)
        }

        const onClear = () => {
          setLocalValue('')
          form.setFieldValue('couponCode', null)
          setClearCouponCode(false)
        }

        useEffect(() => {
          setLocalValue(couponCodeSaved)
          if (couponCodeSaved) setClearCouponCode(true)
        }, [])

        return (
          <>
            <Label for="couponCode">Coupon Code</Label>

            <InputGroup className="floating-label-height">
              <Input className="border-end-0" placeholder="Enter Coupon Code" id="couponCode" name="couponCode"
                     disabled={disabled} value={localValue} onChange={e => setLocalValue(e.target.value)} />

              {clearCouponCode && (
                <InputGroupText className="bg-white">
                  <Button aria-label="Clear" color="danger" className="btn-clear rounded-pill p-0 me-3"
                          onClick={onClear} disabled={disabled}>
                    x
                  </Button>
                </InputGroupText>
              )}

              <Button color="blue-dark" disabled={disabled} onClick={onClickApply}>
                Apply
              </Button>
            </InputGroup>
          </>
        )
      }}
    </FormGroupField>
  )
}

export default Coupon
