import { gql } from 'Utils/apolloClient'

const reservationValidateMutation = gql`
  mutation ReservationValidate($input: ReservationValidateInput!) {
    reservationValidate(input: $input) {
      errorsGroup {
        errors
        isRateUnavailable
        isSiteUnavailable
        isUnavailableByDates
        key
      }
      valid
    }
  }
`

export default reservationValidateMutation
