import { useState } from 'react'
import cnames from 'classnames'
import { isEmpty } from 'Utils/objects'
import { useMutation } from 'Shared/Hooks/useApolloClient'
import { validationSchema } from './validationSchema'
import cancelMutation from './reservationGroupCancelMutation'
import { Button } from 'reactstrap'
import AlertErrors from 'Shared/Alert/AlertErrors'
import CancellationOptions from 'Shared/Formik/CancellationOptions'
import CancellationPolicies from 'Shared/CancelReservation/CancellationPolicies'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import ReservationsList from './ReservationsList'

const INITIAL_VALUES = { refundReservation: false, rejectionReason: 'made_another_reservation',
                         rejectionText: '', reservations: [] }

const CancelForm = ({ cancellationPolicies, links, parentId, reservationsGroupNotCancelled: reservations,
                      toggle }) => {
  const { isAccountEngine } = window.roverData
  const [hasRefund, setHasRefund] = useState(false)
  const [cancelReservations, { loading, error: errorMutation }] = useMutation(cancelMutation, {
    onCompleted: response => {
      if (response.reservationGroupCancel.success)
        if (hasRefund)
          window.location.href = links.refundsParentUrl
        else
          window.location.reload(false)
    },
  })
  const className = cnames('cancel-form', { 'skeleton-loading': loading })
  const reservationsLength = reservations?.length
  const textLabel = isAccountEngine ? '' : 'Write a message to the camper:'
  const placeholder = isAccountEngine ? "Write a message to the campground for why you're cancelling." : ''
  const onSubmit = values => {
    const { refundReservation, rejectionReason, rejectionText, reservations: ids } = values
    if (refundReservation) setHasRefund(true)

    const variables = { id: parentId, ids, rejectionReason, rejectionText }
    cancelReservations({ variables })
  }

  return (
    <FormWithValidations className={className} id="cancel-form" initialValues={INITIAL_VALUES}
                         onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ errors, isValid, setFieldValue, values: { refundReservation, reservations: reservationsFormik } }) => {
        const errorsArray = !isEmpty(errors) && Object.values(errors)
        const formikLength = reservationsFormik?.length
        const isAllChecked = formikLength === reservationsLength
        const isIndeterminate = formikLength > 0 && !isAllChecked

        const onCheckHeader = () => {
          if (isAllChecked) {
            if (reservationsFormik.includes(parentId) && refundReservation)
              setFieldValue('refundReservation', false)
            return setFieldValue('reservations', [])
          }

          const ids = reservations.map(item => item.id)
          setFieldValue('reservations', ids)
        }

        const onCheckRow = reservationId => {
          const temporal = [...reservationsFormik]
          if (temporal.includes(reservationId)) {
            if (reservationId === parentId && refundReservation)
              setFieldValue('refundReservation', false)
            temporal.splice(temporal.indexOf(reservationId), 1)
          } else
            temporal.push(reservationId)

          setFieldValue('reservations', temporal)
        }

        return (
          <>
            {(errorsArray || errorMutation) && (
              <AlertErrors className="m-0 mt-3" errors={errorsArray || null}
                           graphQLErrors={errorMutation || null} />
            )}

            <ReservationsList isAllChecked={isAllChecked} isIndeterminate={isIndeterminate}
                              onCheckHeader={onCheckHeader} onCheckRow={onCheckRow} reservations={reservations} />

            {isAccountEngine && <CancellationPolicies cancellationPolicies={cancellationPolicies} />}

            <p className="fw-bold mb-2">Cancellation Reason</p>

            <CancellationOptions optionName="rejectionReason" showRadioButton={!isAccountEngine}
                                 placeholder={placeholder} textLabel={textLabel} textName="rejectionText" />

            <div className="d-flex justify-content-between mt-4 pt-2">
              <Button color="outline-gray-800" onClick={toggle}>Cancel</Button>

              <Button color="red" data-test="save-button" disabled={!isValid} type="submit">
                {refundReservation ? 'Save & Refund' : 'Save'}
              </Button>
            </div>
          </>
        )
      }}
    </FormWithValidations>
  )
}

export default CancelForm
