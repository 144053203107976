import 'Styles/Background.scss'

const CancellationPolicies = ({ cancellationPolicies }) => (
  <div className="cancelation-policies">
    <p className="fw-bold">Please review the campground's cancellation policy:</p>

    <div className="bg-gray-light p-2 border rounded mb-3">
      <p className="text-smaller-1 fw-bold mb-0">{cancellationPolicies.campgroundCancellationPolicy}</p>

      <small className="text-muted fst-italic mb-0">{cancellationPolicies.campgroundCancellationPolicyNote}</small>
    </div>

    <p className="fw-bold">Please review the RoverPass Cancellation Policy:</p>

    <div className="bg-gray-light p-2 border rounded mb-3">
      <span className="text-smaller-1 fw-bold mb-0">{cancellationPolicies.roverpassCancellationPolicy}</span>
    </div>
  </div>
)

export default CancellationPolicies
