import cnames from 'classnames'
import { FormGroup, Label } from 'reactstrap'
import PhoneInput from 'react-phone-number-input'
import PropTypes from 'prop-types'
import FormGroupField from 'Shared/Formik/FormGroupField'
import { topContries } from 'Utils/PhoneHelpers'
import 'react-phone-number-input/style.css'
import './InternationalPhone.scss'

const InternationalPhone = ({ className, isRequired, label, labelClasses, name, showErrors, sizeInput }) => (
  <FormGroup>
    <FormGroupField className="international-phone" name={name} showErrors={showErrors}>
      {({ field, form, hasErrorClass }) => {
        const classes = {
          label: cnames('main-label d-flex', labelClasses),
          phone: cnames('form-control d-flex align-items-center', className, hasErrorClass,
                        { [`form-control-${sizeInput}`]: sizeInput }),
        }

        const changePhone = value => form.setFieldValue(name, value || '')

        return (
          <>
            {label && (
              <Label className={classes.label} for={name}>
                {label}
                {isRequired && <span className="text-danger ms-1">*</span>}
              </Label>
            )}

            <PhoneInput aria-label="Phone Number" className={classes.phone} countryCallingCodeEditable={false}
                        countryOptionsOrder={topContries} defaultCountry="US" id={name} international
                        limitMaxLength name="phone" onChange={changePhone} value={field.value} />
          </>
        )
      }}
    </FormGroupField>
  </FormGroup>
)

InternationalPhone.defaultProps = {
  isRequired: false,
  label: '',
  labelClasses: '',
  showErrors: false,
  sizeInput: '',
}

InternationalPhone.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelClasses: PropTypes.string,
  name: PropTypes.string.isRequired,
  showErrors: PropTypes.bool,
  sizeInput: PropTypes.string,
}

export default InternationalPhone
